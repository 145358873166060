import { type TokenPair } from '~/models/user'

interface UserState {
  country?: string
  tokenPair?: TokenPair
}

export const useUserState = () => useState<UserState>(
  'user', () => ({})
)
